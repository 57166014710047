import { POSTHOG_PAGES, Subheading } from 'components/form/content/Signup';

// Advisors
import HarvardLogo from 'public/assets/home-page/advisors/HarvardLogo.png';
import JeffersonLogo from 'public/assets/home-page/advisors/JeffersonLogo.png';
import StanfordLogo from 'public/assets/home-page/advisors/StanfordLogo.png';
import UCSFLogo from 'public/assets/home-page/advisors/UCSFLogo.png';
import YaleLogo from 'public/assets/home-page/advisors/YaleLogo.png';

// MediaCoverage
import FastCoLogo from 'public/assets/home-page/mediacoverage/FastCoLogo.svg';
import MHLogo from 'public/assets/home-page/mediacoverage/MHLogo.svg';
import NYTLogo from 'public/assets/home-page/mediacoverage/NYTLogo.svg';
import OutsideLogo from 'public/assets/home-page/mediacoverage/OutsideLogo.svg';
import TheVergeLogo from 'public/assets/home-page/mediacoverage/TheVergeLogo.svg';
import WSJLogo from 'public/assets/home-page/mediacoverage/WSJLogo.svg';

// Investors
import a16zLogo from 'public/assets/footer/a16zLogo.svg';
import BasecampFundLogo from 'public/assets/footer/BasecampFundLogo.svg';
import FounderCollectiveLogo from 'public/assets/footer/FounderCollectiveLogo.svg';
import LoupVenturesLogo from 'public/assets/footer/LoupVenturesLogo.svg';
import ShrugCapitalLogo from 'public/assets/footer/ShrugCapitalLogo.svg';

// Social Media
import ApplePodcastsLogo from 'public/assets/footer/ApplePodcastsLogo.svg';
import FacebookLogo from 'public/assets/footer/FacebookLogo.svg';
import InstagramLogo from 'public/assets/footer/InstagramLogo.svg';
import LinkedInLogo from 'public/assets/footer/LinkedInLogo.svg';
import XLogo from 'public/assets/footer/XLogo.svg';
import YoutubeLogo from 'public/assets/footer/YoutubeLogo.svg';

// WhatIsCGM
import CalendarIcon from 'public/assets/home-page/cgm-feature-icons/calendar.svg';
import ClockIcon from 'public/assets/home-page/cgm-feature-icons/clock.svg';
import DeliverIcon from 'public/assets/home-page/cgm-feature-icons/deliver.svg';
import PhoneIcon from 'public/assets/home-page/cgm-feature-icons/phone.svg';
import SwimIcon from 'public/assets/home-page/cgm-feature-icons/swim.svg';
import TrendingIcon from 'public/assets/home-page/cgm-feature-icons/trending.svg';

import type {
  Advisors,
  AppFeatures,
  Callout,
  FAQs,
  Footer,
  HeroArea,
  HomepageBlock,
  HowItWorks,
  MediaCoverage,
  UserReviews,
  WhatIsCGM,
} from 'types';

/**
 * @summary Utility for loading imgix assets.
 * @param {string} fileName
 * @param {string} format - the format we want imgix to return, if not specified it'll be original file format.
 * @param {string} quality
 * @returns {string} - the remote uri
 */

export const buildUrl = (fileName: string, format?: string, quality?: number): string =>
  `https://levels-public.imgix.net/assets/${fileName}?fm=${format}&q=${quality}`;

const AdvisorsContent: Advisors = {
  schools: [StanfordLogo, UCSFLogo, JeffersonLogo, YaleLogo, HarvardLogo],
  list: [
    // selected advisors to be displayed first
    {
      bio: 'Founder & Director of The UltraWellness Center; Author of 14 New York Times best selling books; Host of The Doctor’s Farmacy – a leading podcast on Health & Wellness',
      degree: 'MD',
      image: buildUrl('AdvisorHyman.png', 'webp'),
      name: 'Mark Hyman',
    },
    {
      bio: 'Professor of Genetics and co-Director of the Paul F. Glenn Center for Biology of Aging Research, Harvard Medical School; Author of Lifespan; Listed as one of TIME Magazine’s “100 most influential people in the world”',
      degree: 'PhD',
      image: buildUrl('AdvisorSinclair.png', 'webp'),
      name: 'David A. Sinclair',
    },
    {
      bio: 'Assistant Professor of Integrative Medicine & Nutritional Sciences, Thomas Jefferson University; Author of three New York Times best selling books, including: The Hormone Cure, The Hormone Reset Diet, and Younger',
      degree: 'MD',
      image: buildUrl('AdvisorGottfried.png', 'webp'),
      name: 'Sara Gottfried',
    },
    {
      bio: 'Associate Professor of Molecular Pharmacology and Physiology, University of South Florida Morsani College of Medicine; Founder of ketonutrition.org',
      degree: 'PhD',
      image: buildUrl('AdvisorDAgostino.png', 'webp'),
      name: 'Dominic D’Agostino',
    },
    // other advisors (alphabetical)
    {
      bio: 'Author of Why We Get Sick; Associate Professor of Physiology and Developmental Biology, Brigham Young University',
      degree: 'PhD',
      image: buildUrl('AdvisorBikman.png', 'webp'),
      name: 'Benjamin Bikman',
    },
    {
      bio: 'Professor Emeritus of Pediatrics, Division of Endocrinology at the University of California, San Francisco; Author of five books, including New York Times best seller Fat Chance and the recent release Metabolical',
      degree: 'MD',
      image: buildUrl('AdvisorLustig.png', 'webp'),
      name: 'Robert Lustig',
    },
    {
      bio: 'Lecturer within the Wellness Department of the Medical School at Stanford University; Advisor to more than 40 companies in digital health, consumer health, and biotechnology',
      degree: 'MD',
      image: buildUrl('AdvisorMaloof.png', 'webp'),
      name: 'Molly Maloof',
    },
    {
      bio: 'Board-Certified Neurologist; Author of six New York Times bestselling books, including: Drop Acid, Grain Brain, The Surprising Truth About Wheat, Carbs and Sugar, among others',
      degree: 'MD',
      image: buildUrl('AdvisorPerlmutter.png', 'webp'),
      name: 'David Perlmutter',
    },
    {
      bio: 'Professor of Medicine and Cellular & Molecular Physiology at Yale; Banting Medal for Lifetime Scientific Achievement from the American Diabetes Association',
      degree: 'MD',
      image: buildUrl('AdvisorShulman.png', 'webp'),
      name: 'Gerald Shulman',
    },
    {
      bio: 'Clinical Professor of Medicine, University of Iowa; Linus Pauling Award recipient (2018); Author of The Wahls Protocol, and The Wahls Protocol Cooking for Life; Founder of The Wahls Lab',
      degree: 'MD',
      image: buildUrl('AdvisorWhals.png', 'webp'),
      name: 'Terry Wahls',
    },
  ],
  sectionTitle: 'Medical & Scientific Advisors',
  title: 'Backed by the leading metabolic health experts',
};

// TODO remove JSX
const AppFeaturesContent: AppFeatures = {
  list: [
    {
      alt: 'app showing Glucose monitoring',
      image: buildUrl('AppFeatures_01.png', 'webp'),
      list: [
        <>
          <Subheading>Eat smarter</Subheading>
          <p>Continuous Glucose Monitors provide you with an instant and accurate view of your blood glucose levels in response to food.</p>
        </>,
        <>
          <Subheading>Exercise & Sleep Better</Subheading>
          <p>Sync with Apple Health kit for valuable insights into how activity and sleep impact your body, so you can optimize both.</p>
        </>,
        <>
          <Subheading>Boost Energy</Subheading>
          <p>
            Use your glucose data to achieve more stable energy, find the foods the work for you and reduce your long-term health risks.
          </p>
        </>,
      ],
      title: 'Measure your response to diet and lifestyle in real-time',
    },
    {
      alt: 'App showing trends',
      image: buildUrl('AppFeatures_02.png', 'webp'),
      list: [
        <>
          <Subheading>Get daily guidance</Subheading>
          <p>Get personalized insights and recommendations based on your body’s unique response to food, activity, sleep and stress.</p>
        </>,
        <>
          <Subheading>Follow expert advice</Subheading>
          <p>
            Our program is informed by the world's leading metabolic health doctors and researchers to help you achieve a longer, healthier
            life
          </p>
        </>,
        <>
          <Subheading>Learn smart swaps</Subheading>
          <p>Receive instant feedback on what foods work for you and how to substitute the ones that don't with healthier alternatives.</p>
        </>,
      ],
      title: 'Learn what works and what is holding you back',
    },
    {
      alt: 'App tracking daily activity',
      image: buildUrl('AppFeatures_03.png', 'webp'),
      list: [
        <>
          <Subheading>Make sustainable changes</Subheading>
          <p>Easy-to-follow checklists help develop simple, sustainable diet and lifestyle habits that bring long-term health benefits.</p>
        </>,
        <>
          <Subheading>Track long-term trends</Subheading>
          <p>Watch your glucose levels improve over time as you make healthy changes, and feel the benefits of better health.</p>
        </>,
        <>
          <Subheading>Stay on target</Subheading>
          <p>Set goals and celebrate streaks of healthy habits that contribute to a longer and healthier life overall.</p>
        </>,
      ],
      title: 'Build habits that lead to a longer, healthier life.',
    },
  ],
};

const CalloutContent: Callout = {
  defaultTitle: (
    <>
      Join 50,000+ members learning <br className={'hidden xs:inline'} /> to eat smarter and live healthier
    </>
  ),
  priceTitle: [
    'Take control of your blood sugar',
    <>
      Join today for $199 <span className={'italic'}>plus</span> $199 for a CGM kit
    </>,
  ],
};

//
// TODO FAQs content should be in Markdown instead
//
const FAQsContent: FAQs = {
  list: [
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.whats-included`,
      title: "What's included in my app membership?",
      text: (
        <>
          <p className={'mb-2'}>
            Your Levels app membership provides full and unrestricted access to the Levels app and all its features, plus access to
            state-of-the-art Continuous Glucose Monitors (CGM).
          </p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.why-need-membership`,
      title: 'Why do I need a membership?',
      text: (
        <>
          <p className={'mb-2'}>
            The Levels membership enables members to have unmitigated access to the Levels app for the duration of the membership term;
            grants access to the Levels community, including:
          </p>
          <ul className="ml-5 text-sm list-disc">
            <li>Expert Q&As</li>
            <li>Monthly newsletters</li>
            <li>Private Facebook group</li>
            <li>Levels recipes</li>
            <li>Community challenges</li>
            <li>Provides the ability to purchase CGMs and/or Levels Labs.</li>
          </ul>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.cancel-membership`,
      title: 'Can I cancel my app membership?',
      text: (
        <>
          <p className={'mb-2'}>
            Yes, you can cancel your app membership at any time. You’ll still be able to access the Levels app until the end of your current
            billing period.
          </p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.rx-required`,
      title: 'Do I need a prescription for a CGM?',
      text: (
        <>
          <p className="mb-4">
            Depending on your choice of CGM you may need a prescription, but don't worry, we can take care of that for you.
          </p>
          <p className="mb-4">
            We'll ask you a few questions about your health, and a licensed physician will review your information to check you are
            eligible.
          </p>
          <p>If you’re approved, we'll handle your prescription and ship your CGM within 3-5 business days.</p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.change-cgm`,
      title: 'Can I change which CGM I use?',
      text: (
        <>
          <p className={'mb-2'}>Yes, you can switch, pause or cancel your CGM plan whenever you want.</p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.own-cgm`,
      title: 'Can I use my own CGM with Levels?',
      text: (
        <>
          <p className={'mb-2'}>
            Yes! If you have a Levels Membership, you're also welcome to take advantage of the Software-Only option. If you need assistance
            or have questions, please contact{' '}
            <a className="text-accent hover:underline" href="mailto:support@levelshealth.com">
              support@levelshealth.com
            </a>
            .
          </p>
          <p>
            Find instructions about how to connect your sensors {''}
            <a className="text-accent hover:underline" href="https://support.levelshealth.com/" rel="noreferrer" target="_blank">
              here
            </a>
            {''} under the <b>Getting Started with Levels</b> section.
          </p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.need-cgm`,
      title: 'Do I need to wear a CGM?',
      text: (
        <>
          <p className={'mb-2'}>
            Although wearing a CGM unlocks the full benefits of the Levels app, you don’t need to wear a CGM continuously for Levels to help
            you improve your health.
          </p>
          <p className={'mb-2'}>Levels recommends using a CGM at least once a quarter or once per month to check in on your progress.</p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.insurance-coverage`,
      title: 'Is Levels covered by my insurance?',
      // TODO: This may change depending how the test efforts go with Truemed. (comms: https://commsbylevels.com/threads/XbgEwIYUk34Y14NV714Rr2)
      text: (
        <>
          <p className={'mb-2'}>
            No, Levels is not eligible for payment through insurance, Health Savings Accounts (HSAs), or Flexible Spending Accounts (FSAs).
          </p>
          <p className={'mb-2'}>
            Making Levels accessible to as many people as possible is a top priority for us, and we’re working hard to make Levels
            affordable no matter what your insurance status is.
          </p>
          <p className={'mb-2'}>
            We’re constantly exploring the best way to increase affordability for our members, so stay tuned for updates.
          </p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.smartphone-compatible`,
      title: 'Is my smartphone compatible?',
      text: (
        <>
          <p className={'mb-2'}>
            Compatibility requirements vary depending on what type of Continuous Glucose Monitor (CGM) you want to use.
          </p>
          <div className={'mb-4'}>
            <p>
              <span className="font-semibold">Dexcom G6</span>
            </p>
            <p>
              Apple – iPhone 7 or later <br />
              Android -{' '}
              <a
                className="text-accent hover:underline"
                href="https://www.dexcom.com/compatibility/dexcom-g6-app"
                rel="noreferrer"
                target="_blank"
              >
                Check compatibility
              </a>
            </p>
          </div>
          <div className={'mb-4'}>
            <p>
              <span className="font-semibold">Freestyle Libre</span>
            </p>
            <p>
              Apple – iPhone 7 or later <br />
              Android - NFC enabled w/ Android 9+
            </p>
          </div>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.data-collection`,
      title: 'What does Levels do with my data?',
      text: (
        <>
          <p className={'mb-2'}>
            First, know that we don’t collect any data we don’t need, and we don’t sell your data (or access to it) to any third party for
            any reason.
          </p>
          <p className={'mb-2'}>
            <span className="font-semibold">Glucose Data:</span> When you connect your Levels account to your CGM, you must opt to share
            your glucose data with Levels so we can show it in the app. We also look at this anonymized aggregate data to understand glucose
            trends to improve the scoring and other app functionality. You can opt out of that sharing by sending us an email at
            privacy@levelshealth.com.
          </p>
          <p className={'mb-2'}>
            <span className="font-semibold">Other Data:</span> You can choose to share data from Apple Health or Google Fit with the Levels
            app so that we can incorporate it into your Levels dashboard and use it to deliver personalized insights, but this is optional.
            Access to this data, along with anything else you share, such as meal logs, is limited internally to those with a business need,
            like support team members, data scientists, product managers, or engineers.
          </p>
          <p className={'mb-2'}>
            Finally, you own your data, not us. You can download it anytime from the Member Portal, and when you leave, you can request that
            we permanently delete it.
          </p>
          <p className={'mb-2'}>
            <a className="text-accent hover:underline" href="https://www.notion.so/6a8d24dc188a4a41ba8e1e5ba433cf70?pvs=21">
              You can find our full privacy policy written in plain English here.
            </a>
          </p>
        </>
      ),
    },
    {
      dataAttr: `${POSTHOG_PAGES.HOMEPAGE}.FAQ.data-study`,
      title: 'How does Levels use my data for the study?',
      text: (
        <>
          <p className={'mb-2'}>
            If you opt into our large-scale clinical research study —{' '}
            <span className="font-semibold">Glucose and Lifestyle Data Patterns in the General Population</span> — you are helping us and
            other researchers better understand how glucose affects people without diagnosed metabolic condition.
          </p>
          <p className={'mb-2'}>
            If you opt in, you agree to share your glucose data for internal research at Levels. The anonymized dataset may also serve as a
            resource for academic research. All of the same data ethics as outlined above apply, including that none of the data is sold to
            any third parties for any reason.
          </p>
          <p className={'mb-2'}>
            If you choose to opt into the study, you can also opt out at any time for any reason simply by contacting support. You can still
            use Levels if you opt out of the study.
          </p>
          <p className={'mb-2'}>
            <a
              className="text-accent hover:underline"
              href="https://www.levelshealth.com/blog/why-levels-does-research-and-what-we-hope-to-learn"
            >
              Learn more about the study here.
            </a>
          </p>
        </>
      ),
    },
  ],
};

const FooterContent: Footer = {
  copyright: `© ${new Date().getFullYear()} Levels. All rights reserved.`,
  disclaimer: [
    'The Levels program, products, and services are intended only for maintaining and encouraging a healthy lifestyle and are not to be used for the diagnosis, cure, management, prevention, or treatment of any disease or condition.',
    'The Levels app, products, and services should never be used for medication management or dosing decisions.',
  ],
  investors: [
    {
      href: 'https://a16z.com/',
      image: a16zLogo,
      name: 'a16z',
    },
    {
      href: 'http://loupventures.com/',
      image: LoupVenturesLogo,
      name: 'Loup Ventures',
    },
    {
      href: 'https://www.shrug.vc/',
      image: ShrugCapitalLogo,
      name: 'Shrug Capital',
    },
    {
      href: 'https://www.avgbasecamp.com/',
      image: BasecampFundLogo,
      name: 'Basecamp Fund',
    },
    {
      href: 'https://www.foundercollective.com/',
      image: FounderCollectiveLogo,
      name: 'Founder Collective',
    },
  ],
  links: [
    {
      href: 'https://www.levelshealth.com/blog',
      name: 'Blog',
    },
    {
      href: 'https://app.levelshealth.com/gift-card',
      name: 'Gift Cards',
    },
    {
      href: 'https://www.levelshealth.com/blog/article_types/how-we-work',
      name: 'Publications',
    },
    {
      href: 'https://www.youtube.com/@LevelsHealth/',
      name: 'Videos',
    },
    {
      href: 'https://www.levelshealth.com/podcasts',
      name: 'Podcasts',
    },
    {
      href: 'https://levelshealth.notion.site/Levels-Assets-for-Media-Content-Creators-January-2022-f7e9b3066e2444cfae92dc786c2f8a32',
      name: 'Media & Creators',
    },
    {
      href: 'https://app.levelshealth.com/investor-updates',
      name: 'Investor Updates',
    },
    {
      href: 'https://levels.link/careers',
      name: 'Careers',
    },
    {
      href: 'https://www.levelshealth.com/inside-the-company-category/friday-forum?preview=true',
      name: 'Friday Forums',
    },
    {
      href: `${process.env.NEXT_PUBLIC_APP_URL}/home/login`,
      name: 'Member Login',
    },
    {
      href: 'https://support.levelshealth.com/',
      name: 'Support',
    },
    {
      href: 'https://support.levelshealth.com/article/43-refund-policy',
      name: 'Refund Policy',
    },
    {
      href: 'https://levels.link/privacy',
      name: 'Privacy Policy',
    },
    {
      href: 'https://www.levelshealth.com/terms-of-service?preview=true',
      name: 'Terms of Services',
    },
  ],
  social: [
    {
      href: 'https://facebook.com/LevelsHealth',
      image: FacebookLogo,
      name: 'Facebook',
    },
    {
      href: 'https://www.instagram.com/levels',
      image: InstagramLogo,
      name: 'Instagram',
    },
    {
      href: 'https://x.com/levels',
      image: XLogo,
      name: 'Twitter',
    },
    {
      href: 'https://www.youtube.com/@LevelsHealth/',
      image: YoutubeLogo,
      name: 'Youtube',
    },
    {
      href: 'https://podcasts.apple.com/ca/podcast/a-whole-new-level/id1563263076',
      image: ApplePodcastsLogo,
      name: 'Apple Podcasts',
    },
    {
      href: 'https://www.linkedin.com/company/levels-health/',
      image: LinkedInLogo,
      name: 'LinkedIn',
    },
  ],
};

const HeroAreaContent: HeroArea = {
  subHeader:
    'Get actionable, personalized insights to improve your health with the most advanced system for measuring your glucose response to food, sleep, and exercise.',
  title: (
    <>
      {'Master your blood sugar '}
      <br className={'hidden xs:inline'} />
      for a longer, healthier life
    </>
  ),
};

const HowItWorksContent: HowItWorks = {
  list: [
    {
      title: 'Become a Levels Member',
      description: 'Get unlimited access to the Levels app and all its features, as well as access to CGMs and blood testing.',
    },
    {
      title: 'Choose a CGM subscription plan',
      description:
        "Choose from two state-of-the-art CGMs, and tell us how often you'd like them sent to you—no doctor's appointment needed.",
    },
    {
      title: 'Track your blood glucose in real time',
      description: 'Apply the CGM and sync it with the Levels app to get a instant, real-time data on your blood glucose levels.',
    },
    {
      title: 'Learn how to improve your health',
      description: 'Build long-lasting, healthy habits with personalised insights and recommendations tailored to you.',
    },
  ],
  title: 'How It Works',
};

const MediaCoverageContent: MediaCoverage = {
  list: [
    {
      image: NYTLogo,
      link: 'https://www.nytimes.com/2021/02/08/well/diet-glucose-monitor.html',
    },
    {
      image: WSJLogo,
      link: 'https://www.wsj.com/articles/how-health-and-fitness-trackers-are-about-to-get-a-lot-more-granular-11641999617',
    },
    {
      image: MHLogo,
      link: 'https://www.menshealth.com/health/a35541222/cgm-continual-glucose-monitor-levels-supersapiens/',
    },
    {
      image: OutsideLogo,
      link: 'https://www.outsideonline.com/outdoor-gear/tools/best-new-fitness-trackers-2022/',
    },
    {
      image: FastCoLogo,
      link: 'https://www.fastcompany.com/90741642/this-former-spacex-engineer-wants-to-prevent-you-from-getting-diabetes',
    },
    {
      image: TheVergeLogo,
      link: 'https://www.theverge.com/22794594/levels-health-glucose-fitness-tracker-review',
    },
  ],
};

const UserReviewsContent: UserReviews = {
  list: [
    {
      date: '07/29/2022',
      description:
        'I started using Levels in March 2021 and I keep coming back to them each time I compare it to similar companies. I recommend the app to all of my clients and family.',
      name: 'SaltyDynamics',
      title: 'One of my favorite apps',
    },
    {
      date: '10/14/2022',
      description:
        'As a nutritionist, I tried Levels so that I could refer my clients to a resource to support health optimization and weight loss. After months of trial, I was able to fine tune my already healthy diet to boost my energy, mood, sleep, hormones and even some weight loss.',
      name: 'Mona Sharma',
      title: 'Game changer for health optimization',
    },
    {
      date: '10/15/2022',
      description:
        'Wearing a glucose monitor made me realize that my crashes in energy were due to my blood glucose. Knowing this, I was keen to stabalize my blood glucose levels. Levels allows me to monitor how foods and activities impact my blood glucose so I can make more informed decsions.',
      name: 'Anna J Mac',
      title: 'Great health tech',
    },
  ],
  text: ['4.7 stars out of 5', 'from 1,400+ reviews'],
  title: 'Join 50,000+ members achieving real results',
};

const WhatIsCGMContent: WhatIsCGM = {
  list: [
    {
      icon: ClockIcon,
      text: 'Track your glucose 24/7',
    },
    {
      icon: CalendarIcon,
      text: 'Wear each for up to 14 days',
    },
    {
      icon: DeliverIcon,
      text: 'Feedback in real-time',
    },
    {
      icon: PhoneIcon,
      text: 'Connect your smartphone',
    },
    {
      icon: SwimIcon,
      text: 'Swimming & exercise safe',
    },
    {
      icon: TrendingIcon,
      text: 'Delivered to your door',
    },
  ],
  sectionTitle: 'What’s a Continuous Glucose Monitor?',
  title: 'A small but mighty device for tracking your blood glucose',
};

/**
 * @summary Mapping of block name to dynamic content.
 * @returns {Object}
 */

const content = {
  'Advisors': AdvisorsContent,
  'AppFeatures': AppFeaturesContent,
  'Callout': CalloutContent,
  'FAQs': FAQsContent,
  'Footer': FooterContent,
  'HowItWorks': HowItWorksContent,
  'HeroArea': HeroAreaContent,
  'MediaCoverage': MediaCoverageContent,
  'UserReviews': UserReviewsContent,
  'WhatIsCGM': WhatIsCGMContent,
};

/**
 * @summary Loads the content for each homepage block.
 * This eventually should be replaced by a proper CMS integration (e.g. Storyblok)
 * @param {HomepageBlock} pageName
 * @returns
 */

const loadContent = (pageName: HomepageBlock) => content[pageName];
export default loadContent;
